import "element-plus/lib/components/message-box/style/css";
import _ElMessageBox from "element-plus/lib/components/message-box";
import BackgroundVideo from '@/components/backgroundVideo.vue';
import industryInstructions from '@/components/industryInstructions.vue';
import industryHighlightText from '@/components/industryHighlightText.vue';
import mynFeatures from '@/components/mynFeatures.vue';
import '@/components/industry.css';
export default {
  name: 'Logistics',
  components: {
    BackgroundVideo: BackgroundVideo,
    industryInstructions: industryInstructions,
    industryHighlightText: industryHighlightText,
    mynFeatures: mynFeatures
  },
  methods: {
    helipad: function helipad() {
      _ElMessageBox.alert(' <div class="center"> <div class="card black"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> Log.</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Helipad</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      }); // console.log('Click on Helipad Received', event.target.tagName);

    },
    storage: function storage() {
      _ElMessageBox.alert(' <div class="center"> <div class="card purple"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> Log.</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Storage</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      }); // console.log('Click on Storage Received', event.target.tagName);

    },
    contStorage: function contStorage() {
      _ElMessageBox.alert(' <div class="center"> <div class="card"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> Log.</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Container Storage</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      }); // console.log('Click on Container Storage Received', event.target.tagName);

    },
    wareHouse: function wareHouse() {
      _ElMessageBox.alert(' <div class="center"> <div class="card blue"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> Log.</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Warehouse</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      }); // console.log('Click on Warehouse Received', event.target.tagName);

    },
    containerBoat: function containerBoat() {
      _ElMessageBox.alert(' <div class="center"> <div class="card green"> <div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> Log.</small></div> <!-- <div class="more-info"> <h1>Advantages</h1> <div class="coords"> <span>Group Name</span> <span>Joined January 2019</span> </div> <div class="coords"> <span>Position/Role</span> <span>City, Country</span> </div> <div class="stats"> <div> <div class="title">Awards</div> <i class="fa fa-trophy"></i> <div class="value">2</div> </div> <div> <div class="title">Matches</div> <i class="fa fa-gamepad"></i> <div class="value">27</div> </div> <div> <div class="title">Pals</div> <i class="fa fa-group"></i> <div class="value">123</div> </div> <div> <div class="title">Coffee</div> <i class="fa fa-coffee"></i> <div class="value infinity">∞</div> </div> </div> </div> --> </div> <div class="general"> <h1>Container Ship</h1> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce a volutpat mauris, at molestie lacus. Nam vestibulum sodales odio ut pulvinar.</p> <span class="more">Mouse over the card for more info</span> </div> </div> </div>', {
        dangerouslyUseHTMLString: true
      }); // console.log('Click on Container Boat Received', event.target.tagName);

    }
  }
};